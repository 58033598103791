<template>
	<div class="main-container">
		<Loader />
		<img
			:src="toggleMenuHeaderIcon"
			alt="menu-header"
			class="menu-logo"
			@click="onOpenMenuItem"
		/>
		<ul
			:class="applyMenuOpacityTransition"
			:style="{ display: isMenuOpened === false ? 'none' : 'block' }"
		>
			<li
				v-for="(menuItem, index) in menuItems"
				:key="index"
				class="menu-item"
				@click="navigateToPage(menuItem.link)"
			>
				{{ menuItem.item }}
			</li>
		</ul>
		<NavHeader
			:items="headerItem"
			:userprofile="profile"
			:homeURL="{ name: 'landing-page.landing-page' }"
			:logo="logo"
			:navigateToPage="navigateToPage"
		>
		</NavHeader>
		<!-- <Breadcrumb
			v-if="
				this.$route.name == 'nationunitedlax' // ||
			"
		/>
		<Breadcrumb-lac v-if="this.$route.name == 'landing-page.ncl-lacrosse'" /> -->
		<!-- <BreadcrumbInternship
	v-if="this.$route.name == 'landing-page.internship'"
	/> -->
		<div :class="this.$route.meta.mainClass == '' ? '' : 'main-content'">
			<transition
				name="router-anim"
				:enter-active-class="`animated ${animated.enter}`"
				mode="out-in"
				:leave-active-class="`animated ${animated.exit}`"
			>
				<router-view />
			</transition>
		</div>
			<Footer />
		<div id="back-to-top">
			<a class="top" href="#top" id="top"> <i class="fa fa-angle-up"></i> </a>
		</div>
		<div
			v-if="isMenuOpened"
			@click="isMenuOpened = false"
			:class="toggleOverlayClass"
		></div>
	</div>
</template>
<script>
import { core } from "../config/pluginInit";
import Loader from "../components/core/loader/Loader";
import profile from "../assets/images/frontend/user/user.jpg";
import loader from "../assets/images/logo-full.svg";
import NavHeader from "../components/core/navbars/FrontendNavBlurred";
// import Breadcrumb from "../views/FrontendPages/Components/Breadcrumb/Breadcrumb";
import Footer from "../views/FrontendPages/Components/Footer/FooterNLSE";
// import BreadcrumbLac from "../views/FrontendPages/Components/Breadcrumb/BreadcrumbLac.vue";
import router from "../router";
// import BreadcrumbInternship from '../views/FrontendPages/Components/Breadcrumb/BreadcrumbInternship.vue'

export default {
	name: "BackendLayout",
	components: {
		Loader,
		NavHeader,
		//Breadcrumb,
		Footer,
		// BreadcrumbLac,
		// BreadcrumbInternship
	},
	mounted() {
		core.index();
	},
	data() {
		return {
			profile: "",
			animated: { enter: "fadeInUp", exit: "fadeOut" },
			userProfile: profile,
			onlyLogo: false,
			onlyLogoText: false,
			logo: require("../assets/images/nlse-logo.svg"),
			isMenuOpened: false,
			headerMenuOpenIcon: require("../assets/images/logo/menu-open.png"),
			headerMenuCloseIcon: require("../assets/images/logo/menu-close.png"),
			headerItem: [
				{ title: "What's On", link: "/whatson", child: false },
				{ title: "How to Watch", link: "/watchpage", child: false },
				{ title: "NLSE+", link: "/nlse+", child: false },
				{ title: "News", link: "/newsroom", child: false },
				{ title: "Leagues", link: "/leagues", child: false },
				{ title: "About NLSE", link: "/aboutus", child: false },

				// {
				//   title: 'Collegiate League',
				//   link: '#',
				//   child: false,
				//   children: [
				//     {
				//       title: 'Lacrosse',
				//       link: '/ncl-lacrosse',
				//       child: false
				//     }
				//   ]
				// },
				// { title: 'NCL', link: '/ncl', child: false },
				// {
				// 	title: "More",
				// 	link: "#",
				// 	child: false,
				// 	children: [
				// 		{
				// 			title: "About Us",
				// 			link: "/about",
				// 			child: false,
				// 		},
				// 		{
				// 			title: "Digital Innovation",
				// 			link: "/innovations",
				// 			child: false,
				// 		},
				// 		{
				// 			title: "Internship",
				// 			link: "/internship",
				// 			child: false,
				// 		},
				// 		{
				// 			title: "Contact Us",
				// 			link: "/contact",
				// 			child: false,
				// 		},
				// 	],
				// },
			],
			menuItems: [
				{
					item: "What's On",
					link: "/whatson",
				},
				{
					item: "How to Watch",
					link: "/watchpage",
				},
				{
					item: "NLSE+",
					link: "/nlse+",
				},
				{
					item: "News",
					link: "/newsroom",
				},
				{
					item: "Leagues",
					link: "/leagues",
				},
				{
					item: "About NLSE",
					link: "/aboutus",
				},
				// {
				// 	item: "Advertisers & Sponsors",
				// 	link: "/advertise",
				// },
				// {
				// 	item: "NLSE Announcements",
				// 	link: "/",
				// },
				{
					item: "Careers at NLSE",
					link: "/opportunites",
				},
				{
					item: "Contact Us",
					link: "/contact",
				},
			],
		};
	},
	computed: {
		toggleMenuHeaderIcon() {
			return this.isMenuOpened
				? this.headerMenuCloseIcon
				: this.headerMenuOpenIcon;
		},
		toggleOverlayClass() {
			return this.isMenuOpened ? "overlay" : "";
		},
		applyMenuOpacityTransition() {
			return this.isMenuOpened
				? "menu-container menu-open"
				: "menu-container menu-close";
		},
	},
	methods: {
		changeLogo(e) {
			this.logo = e;
		},
		routerAnimationChange(e) {
			this.animated = e;
		},
		onOpenMenuItem() {
			this.isMenuOpened = !this.isMenuOpened;
		},
		navigateToPage(itemLink) {
			this.isMenuOpened = false;
			// Send tracking event to Google Analytics
			this.$gtag.event('navigate', {
			event_category: itemLink,
			event_label: `Navigated to ${itemLink}`,
			value: 1,
			});
			router.push(itemLink);
		},
	},
};
</script>
<style lang="scss">
@import url("../assets/css/custom.css");
@import url("../assets/css/developer.css");
@import "../assets/scss/frontend.scss";
@import "../assets/scss/backend.scss";

// body {
// 	// background-image: url("../assets/images/frontend/home/background_texture_dark.png");
// 	background-image: url("../assets/images/background/background_bubbles_texture.png");
// 	background-size: cover !important;
// 	background-attachment: fixed !important;
// 	background-position: center !important;
// 	background-repeat: no-repeat !important;
// }
body {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1; /* Make sure the background stays behind other content */
}
body::before {
	content: "";
	position: fixed; /* Fixed position */
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1; /* Sit on top */
	background-image: url("../assets/images/background/AdobeStock_433167186_bw/AdobeStock_433167186_bw.png");
 	background-size: cover;
 	background-position: center;
 	background-repeat: no-repeat;
	background-color: #1C1C1C;
}

.menu-logo {
	width: 20px;
	height: 20px;
	position: fixed;
	top: 25px;
	left: 20px;
	z-index: 100;
}
.menu-container {
	position: fixed;
	top: 60px;
	left: 20px;
	z-index: 100;
	list-style-type: none;
	margin: 0;
	opacity: 0;
}
.menu-open {
	opacity: 1;
	transition: opacity 0.7s ease-in-out;
}
.menu-close {
	opacity: 0;
	transition: opacity 0.7s ease-in-out;
}

.menu-item {
	font-family: 'Segoe UI Variable Display', sans-serif;
	font-size: 28px;
	font-weight: bold;
	color: white;
	text-align: left;
	padding-left: 0;
	margin-left: 0;
}
.menu-logo:hover {
	cursor: pointer;
}
.menu-item:hover {
	font-size: 28px;
	color: var(--iq-primary) !important;
	cursor: pointer;
}
.foreground {
	opacity: 0;
}
.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: black;
	opacity: 0.85;
	transition: opacity 0.5 ease-in-out;
	z-index: 50;
}
</style>
