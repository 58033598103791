<template>
    <div>
        <footer
            id="contact"
            class="footer-one bg--footer"
            :style="{ 'background-color': '#040505 !important' }"
        >
            <hr class="red-line" />

            <!-- Address -->
            <div class="footer-top">
                <b-container fluid>
                    <b-row class="d-flex justify-content-between">
                        <b-col md="8">
                            <b-row class="justify-content-center justify-content-md-start">
                                <b-col
                                    md="4"
                                    class="logo justify-content-center justify-content-md-start d-flex"
                                >
                                    <img
                                        :src="logo"
                                        alt="Next Level Sports and Entertainment Logo"
                                    />
                                </b-col>
                                <b-col md="7" class="social-icons-container">
                                    <a
                                        v-for="(icon, index) in icons"
                                        :key="index"
                                        :href="icon.link"
                                        :aria-label="icon.alt"
                                        :title="icon.name"
                                        class="social-icon iq-view-all"
                                    >
                                        <i
                                            :class="icon.class"
                                            :aria-label="icon.alt"
                                            :title="icon.name"
                                        ></i>
                                    </a>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="content">
                                    <p>{{ content }}</p>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="3" class="page-list-wrapper">
                            <a
                                v-for="(page, index) in pages"
                                :key="index"
                                class="page-list-item"
                                @click="navigateToPage(page.link)"
                            >
                                {{ page.name }}
                            </a>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <!-- Address END -->
        </footer>
    </div>
</template>

<script>
import router from "../../../../router";

export default {
    name: "Footer",
    components: {},
    data() {
        return {
            logo: require("@/assets/images/logo/nlse-logo-caption.svg"),
            content:
                "© 2023 Next Level Sports and Entertainment (NLSE). All videos and shows on this platform are trademarks of, and all related images and content are the property of, Next Level Sports and Entertainment Inc. Duplication and copy of this is strictly prohibited. All rights reserved.",
            icons: [
                {
                    name: "Facebook",
                    link: "https://www.facebook.com/nlsetelevision",
                    class: "fab fa-facebook-f",
                    alt: "Facebook Icon",
                },
                {
                    name: "Twitter",
                    link: "https://twitter.com/nlsetv",
                    class: "fab x-twitter-logo",
                    alt: "Twitter Icon",
                },
                {
                    name: "Instagram",
                    link: "https://instagram.com/nlsetv",
                    class: "fab fa-instagram",
                    alt: "Instagram Icon",
                },
                {
                    name: "Linkedin",
                    link: "https://www.linkedin.com/company/nlsetv/",
                    class: "fab fa-linkedin",
                    alt: "linkedin Icon",
                },
                {
                    name: "Snapchat",
                    link: "https://story.snapchat.com/p/9f26b5f3-96c1-4ccd-833a-dd17543551e7/1436212576178176?sender_web_id=3c66bde5-4741-41c2-a52b-7a46ba5fdbb0&device_type=desktop&is_copy_url=true",
                    class: "fab fa-snapchat",
                    alt: "Snapchat Icon",
                },
                // {
                //  name: "Tiktok",
                //  link: "https://www.tiktok.com/@nlsetv",
                //  class: "fab fa-tiktok",
                //  alt: "Tiktok Icon",
                // },
                // {
                //  name: "YouTube",
                //  link: "https://www.youtube.com/channel/nlsetv",
                //  class: "fab fa-youtube",
                //  alt: "YouTube Icon",
                // },
            ],
            pages: [
                {
                    name: "What's On",
                    link: "/whatson",
                },

                // {
                //  name: "Community",
                //  link: "#",
                // },

                {
                    name: "How to Watch",
                    link: "/watchpage",
                },
                {
                    name: "NLSE+",
                    link: "/nlse+",
                },
                // {
                //  name: "Advertisers & Sponsors",
                //  link: "/advertise",
                // },
                {
                    name: "Careers",
                    link: "/opportunites",
                },

                // {
                //  name: "Intern at NLSE",
                //  link: "/opportunites#internship",
                // },
                {
                    name: "News",
                    link: "/newsroom",
                },
                // {
                //  name: "Privacy Statement",
                //  link: "/privacy-policy",
                // },
                {
                    name: "Contact Us",
                    link: "/contact",
                },
                {
                    name: "Leagues",
                    link: "/leagues",
                },

                {
                    name: "About NLSE",
                    link: "/aboutus",
                },
                {
                    name: "Terms of Service",
                    link: "/termsofuse",
                },
            ],
        };
    },
    mounted() {},
    methods: {
        navigateToPage(itemLink) {
			// Send tracking event to Google Analytics
			this.$gtag.event('navigate', {
			event_category: itemLink,
			event_label: `Navigated to ${itemLink}`,
			value: 1,
			});
			router.push(itemLink);
		},
    }
};
</script>
<style scoped>

footer {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular font weight */
    color: #BBBBBB;
}

.logo {
    margin-bottom: 20px;
    /* width: 150px; */
}

.logo img {
    /* max-width: 100%; */
    height: auto;
    width: 13rem;
}
/**the social icons are positioned under NLSE logo for width < 767px */
.social-icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-icon {
    color: #ffffff;
}
.page-list-item:hover,
.social-icon:hover {
    color: var(--iq-primary) !important;
    transition: color 0.2s ease-in;
}
.social-icons-container a {
    margin-right: 10px;
}

.social-icons-container a:last-child {
    margin-right: 10px;
}

.social-icons-container i {
    font-size: 2.5rem;
}

.page-list-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}

.page-list-item {
    margin-bottom: 10px;
    color: #bbbbbb;
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular font weight */
    color: #BBBBBB;
    cursor: pointer;
}

@media (min-width: 768px) {
    .page-list-wrapper {
        grid-template-columns: repeat(2, auto);
    }
    .page-list-item {
        margin-bottom: 0;
    }
	/**social icons and NLSE logo on the same row for width > 768px */
	.social-icons-container {
	padding-left: 6rem;
	}
    .social-icon {
        padding-right: 0.5rem;
    }
}

.red-line {
    background: #eb0a0a 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 5px;
    margin-top: 0;
}
.content {
    font-weight: 400;
    letter-spacing: 0.2px;
}
</style>